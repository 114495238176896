import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import navigationBarPhoto from "../../../../assets/images/navBar.png";
import Container from "../../../../bootstrap/Container/";
import navigationData from "../../../../data/navigation.json";
import SinglePhoto from "../../SinglePhoto/";
import TrackOrder from "../../TrackOrder";
import {
  HeaderBottomWrapper,
  Logo,
  LogoLink,
  NavigationBar,
  NavigationBarIcon,
  NavigationUl,
  NavigationWithOutRes,
  NavigationWrapper,
  NavLink,
  SingleNavItem,
  Wrapper,
} from "./HeaderBottom.styles.js";

const HeaderBottom = ({ userProfileInfo }) => {
  const { webSettingData } = useSelector((state) => state.busLists);
  const [showNavItem, setShowNavItem] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(localStorage.getItem("token"));
  }, []);

  const navigationItem = () => {
    if (userProfileInfo) {
      return navigationData.filter((item) => item.text !== "Login");
    } else {
      return navigationData;
    }
  };

  return (
    <Wrapper bg={webSettingData?.headercolor}>
      <Container>
        <HeaderBottomWrapper>
          <Logo>
            <LogoLink to="/">
              <SinglePhoto
                img={webSettingData?.headerlogo}
                alt="logo"
                style={{ height: "50px" }}
              />
            </LogoLink>
            <NavigationBar>
              <NavigationBarIcon
                src={navigationBarPhoto}
                alt="navigationIcon"
                onClick={() => setShowNavItem((prevState) => !prevState)}
              />
            </NavigationBar>
          </Logo>
          <NavigationWrapper>
            <NavigationWithOutRes>
              {navigationItem().map((item) => (
                <SingleNavItem key={item.id}>
                  <NavLink to={`${item.url}`}>{item.text}</NavLink>
                </SingleNavItem>
              ))}

              <SingleNavItem>
                <Popup
                  trigger={<NavLink to="#"> Track </NavLink>}
                  position="bottom center"
                >
                  <TrackOrder />
                </Popup>
              </SingleNavItem>

              {token && (
                <SingleNavItem>
                  <NavLink to="/tickets">{userProfileInfo?.first_name}</NavLink>
                </SingleNavItem>
              )}
            </NavigationWithOutRes>

            {showNavItem && (
              <NavigationUl>
                {navigationItem().map((item) => (
                  <SingleNavItem key={item.id}>
                    <NavLink to={`${item.url}`}>{item.text}</NavLink>
                  </SingleNavItem>
                ))}

                <SingleNavItem>
                  <Popup
                    trigger={<NavLink to="#"> Track </NavLink>}
                    position="bottom center"
                  >
                    <TrackOrder />
                  </Popup>
                </SingleNavItem>

                {token && (
                  <SingleNavItem>
                    <NavLink to="/tickets">
                      {userProfileInfo?.first_name}
                    </NavLink>
                  </SingleNavItem>
                )}
              </NavigationUl>
            )}
          </NavigationWrapper>
        </HeaderBottomWrapper>
      </Container>
    </Wrapper>
  );
};

export default HeaderBottom;
