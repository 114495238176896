import { useEffect, useState } from "react";
import { Wrapper } from "./NewPayPal.styles";

const NewPayPal = () => {
  const [price, setPrice] = useState(null);

  useEffect(() => {
    setPrice(JSON.parse(localStorage.getItem("fareSummry")));
  }, []);

  const getPaypalData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/paymethods/paypal`
    );
    const result = await response.json();

    if (result?.status === "success") {
      paymentPayPal(result?.data?.client_id);
    }
  };

  const paymentPayPal = (client_id) => {
    window.paypal.Button.render(
      {
        // Configure environment
        env: "production",
        client: {
          production: client_id,
        },
        // Customize button (optional)
        locale: "en_US",
        style: {
          size: "small",
          color: "gold",
          shape: "pill",
        },

        // Enable Pay Now checkout flow (optional)
        commit: true,

        // Set up a payment
        payment: function (data, actions) {
          return actions.payment.create({
            transactions: [
              {
                amount: {
                  total: "0.01",
                  currency: "USD",
                },
              },
            ],
          });
        },
        // Execute the payment
        onAuthorize: function (data, actions) {
          return actions.payment.execute().then(function () {
            // Show a confirmation message to the buyer
            window.alert("Thank you for your purchase!");
          });
        },
      },
      "#paypal-button"
    );
  };

  useEffect(() => {
    try {
      getPaypalData();
    } catch (error) {
      console.log("paypal", error);
    }
  }, []);

  return (
    <Wrapper>
      <div id="paypal-button"></div>
    </Wrapper>
  );
};

export default NewPayPal;
