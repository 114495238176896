import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import languageData from "../../../lib/lang.config.json";
import NewPayPal from "../PaymentGateway/NewPayPal";
import Paystack from "../PaymentGateway/Paystack";
import Razorpay from "../PaymentGateway/Razorpay";
import StripeCheckoutBtn from "../PaymentGateway/StripeCheckoutBtn";
import {
  ButtonWrapper,
  CancelButton,
  Label,
  PaymentAndButton,
  PaymentButton,
  PaymentInput,
  PaymentMethod,
  PaymentUl,
  SinglePayment,
} from "./Payment.styles";

const Payment = ({
  handleSubmitPayment,
  paymentStutas,
  setPaymentStutas,
  paymentGateway,
  setpaymentGateway,
  passengerInformation,
  allBookingInformation,
  setLoading,
}) => {
  const { webSettingData } = useSelector((state) => state.busLists);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const history = useHistory();

  const getPaymentMethods = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_MODULE_DOMAIN}/paymethods`
    );
    const result = await response.json();
    if (result?.status === "success") {
      setPaymentMethods(result?.data);
    }
  };

  useEffect(() => {
    try {
      getPaymentMethods();
    } catch (error) {
      console.log("paymentMethods", error);
    }
  }, []);

  const handleCancel = () => {
    history.push("/");

    localStorage.removeItem("bookingInfo");
    localStorage.removeItem("searchInfo");
    localStorage.removeItem("returnFirstJourneyInfo");
    localStorage.removeItem("journeyInfo");
    localStorage.removeItem("fareSummry");
    localStorage.removeItem("discount");
    localStorage.removeItem("regular");
    localStorage.removeItem("return");
    localStorage.removeItem("bookingInfoTax");
    localStorage.removeItem("journeyInfoTax");
    localStorage.removeItem("subtripId");
  };

  const paymentStutasChange = (e) => {
    if (!passengerInformation?.login_email) {
      toast("set all information");
      return;
    } else if (!passengerInformation?.login_mobile) {
      toast("set all information");
      return;
    } else if (!passengerInformation?.first_name) {
      toast("set all information");
      return;
    } else if (!passengerInformation?.last_name) {
      toast("set all information");
      return;
    } else if (!passengerInformation?.id_number) {
      toast("set all information");
      return;
    } else if (!passengerInformation?.zip_code) {
      toast("set all information");
      return;
    } else if (!passengerInformation?.city) {
      toast("set all information");
      return;
    } else if (!passengerInformation?.address) {
      toast("set all information");
      return;
    } else if (!passengerInformation?.country_id) {
      toast("set all information");
      return;
    }

    setPaymentStutas(e.target.value);
    setpaymentGateway("");
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    var ele = document.querySelectorAll("input[type='radio']:checked");
    for (let index = 0; index < ele.length; index++) {
      ele[index].checked = false;
    }
    setPaymentStutas("");
  }, [passengerInformation]);

  return (
    <>
      <PaymentMethod>
        <SinglePayment>
          <PaymentInput
            type="radio"
            id="payNow"
            name="paymentStutas"
            value="Pay Now"
            onChange={(e) => paymentStutasChange(e)}
          />
          <Label htmlFor="payNow">
            {
              languageData?.checkout_page_payNow_radio_btn[
                webSettingData?.language
              ]
            }
          </Label>
        </SinglePayment>

        <SinglePayment>
          <PaymentInput
            type="radio"
            id="payLatter"
            name="paymentStutas"
            value="Pay Latter"
            onChange={(e) => paymentStutasChange(e)}
          />
          <Label htmlFor="payLatter">
            {
              languageData?.checkout_page_payLatter_radio_btn[
                webSettingData?.language
              ]
            }
          </Label>
        </SinglePayment>
      </PaymentMethod>

      <PaymentAndButton>
        {paymentStutas === "Pay Now" && (
          <PaymentUl>
            {paymentMethods?.map((item) => (
              <SinglePayment key={item.id}>
                <PaymentInput
                  type="radio"
                  id={item.name}
                  name="payment"
                  value={item.name}
                  onChange={(e) => setpaymentGateway(e.target.value)}
                />
                <Label htmlFor={item.name}>{item.name}</Label>
              </SinglePayment>
            ))}
          </PaymentUl>
        )}

        {paymentStutas === "Pay Now" && (
          <>
            {(() => {
              if (validateEmail(passengerInformation.login_email)) {
                const emailError = document.getElementById("email-error");
                if (emailError) {
                  emailError.innerHTML = "";
                }
                return (
                  <ButtonWrapper>
                    <CancelButton onClick={handleCancel}>
                      {
                        languageData?.checkout_page_cancel_btn[
                          webSettingData?.language
                        ]
                      }
                    </CancelButton>
                    {paymentGateway ? (
                      <>
                        {paymentGateway === "paypal" && <NewPayPal />}

                        {paymentGateway === "paystack" && (
                          <Paystack
                            passengerInformation={passengerInformation}
                            allBookingInformation={allBookingInformation}
                            setLoading={setLoading}
                          />
                        )}
                        {paymentGateway === "stripe" && (
                          <StripeCheckoutBtn
                            allBookingInformation={allBookingInformation}
                            setLoading={setLoading}
                          />
                        )}

                        {paymentGateway === "razorpay" && (
                          <Razorpay
                            allBookingInformation={allBookingInformation}
                            setLoading={setLoading}
                          />
                        )}
                      </>
                    ) : (
                      <PaymentButton
                        onClick={() => handleSubmitPayment(paymentStutas)}
                        btnbgcolor={webSettingData?.buttoncolor}
                        btnbghvcolor={webSettingData?.buttoncolorhover}
                        btntextcolor={webSettingData?.buttontextcolor}
                      >
                        {
                          languageData?.checkout_page_make_payment_btn[
                            webSettingData?.language
                          ]
                        }
                      </PaymentButton>
                    )}
                  </ButtonWrapper>
                );
              } else {
                document.getElementById("email-error").innerHTML =
                  "email is not valid";
              }
            })()}
          </>
        )}
      </PaymentAndButton>

      {paymentStutas === "Pay Latter" && (
        <ButtonWrapper>
          <CancelButton onClick={handleCancel}>
            {languageData?.checkout_page_cancel_btn[webSettingData?.language]}
          </CancelButton>
          <PaymentButton
            onClick={() => handleSubmitPayment(paymentStutas)}
            btnbghvcolor={webSettingData?.buttoncolorhover}
            btnbgcolor={webSettingData?.buttoncolor}
          >
            {
              languageData?.checkout_page_book_your_ticket_btn[
                webSettingData?.language
              ]
            }
          </PaymentButton>
        </ButtonWrapper>
      )}
    </>
  );
};

export default Payment;
